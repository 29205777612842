<template>
  <div>
    <div class="carousel-form">
      <!-- 是否推荐 -->
      <span style="margin-left:20px">是否推荐：</span>
      <el-select v-model="value2" style="width:100px" @change="searchInfo(pageSize,0)">
        <el-option label="全部" value="0"></el-option>
        <el-option label="是" value="1"></el-option>
        <el-option label="否" value="2"></el-option>
      </el-select>
      <!-- 是否精选 -->
      <span style="margin-left:20px">是否精选：</span>
      <el-select v-model="value1" style="width:100px;" @change="searchInfo(pageSize,0)">
        <el-option label="全部" value="0"></el-option>
        <el-option label="是" value="1"></el-option>
        <el-option label="否" value="2"></el-option>
      </el-select>
      <el-input
        v-model="input"
        placeholder="按照标题查找"
        @input="searchInfo(pageSize,0)"
        style="width: 300px;margin-left:20px"
      ></el-input>
      <!-- <el-button type="primary"  @click="addCarousel">新增</el-button> -->
    </div>
    <div class="carousel-table">
      <el-table :data="tableData" border style="width: 100%;">
        <el-table-column width="50px" style=" text-align: center;" prop="id" label="ID"></el-table-column>
        <!-- <el-table-column prop="img" label="文章图" width="250">
          <template slot-scope="scope">
            <img :src="scope.row.img" alt style="width:150px" />
          </template>
        </el-table-column>-->
        <el-table-column label="文章标题" width="300">
          <template slot-scope="scope">
            <span @click="detail(scope.row.id)">{{scope.row.title}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="author" label="文章作者" width="150"></el-table-column>
        <el-table-column label="是否推荐" width="150">
          <template slot-scope="scope">
            <!-- <el-button  class="button-icon" type="text" size="small" @click="recommend(scope.row)">{{ (scope.row.recommendid=="0"?"是":"否") }}</el-button> -->
            <span v-if="tableData[scope.$index].isRecommend==1" type="text" size="small">是</span>
            <span v-if="tableData[scope.$index].isRecommend==0" type="text" size="small">否</span>
          </template>
        </el-table-column>
        <el-table-column label="是否精选" width="150">
          <template slot-scope="scope">
            <span v-if="tableData[scope.$index].isChoiceness==1" type="text" size="small">是</span>
            <span v-if="tableData[scope.$index].isChoiceness==0" type="text" size="small">否</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="250">
          <template slot-scope="scope">
            <el-button type="text" size="small"  @click="see( scope.row)">查看详情</el-button>
            <el-button @click="modify(scope.row)"  type="text" size="small">编辑</el-button>
            <el-button type="text" size="small"  @click="Delete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="position:relative;width:100%;height:30px;padding-top:20px;text-align:right;">
      <el-pagination
        background
        @current-change="currentPage"
        :page-size="pageSize"
        layout="prev, pager, next"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { DELETE, QUERYED, INSERT } from "@/services/dao.js";
export default {
  data() {
    return {
      
      name: "",
      input: "",
      value1: "请选择",
      value2: "请选择",
      tableData: [],
      table: [],
      pageNum: 0,
      pageSize: 10,
      total: 0
    };
  },
  created() {
    ////首次页面加载
    this.searchInfo(this.pageSize, 0);
  },
  methods: {
    //c查询
    // flite(input) {
    //   let that = this;
    //   //  filter  数组API    includes  字符串API
    //   //  和数组中的 includes类似
    //   that.tableData = that.table.filter(Val => {
    //     if (
    //       Val.title.includes(that.input)
    //       // Val.author.includes(that.input)
    //       // Val.isChoiceness.includes(that.input)
    //       // Val.isRecommend.includes(that.input)
    //     ) {
    //       that.tableData.push(Val);
    //       return that.tableData;
    //     }
    //   });
    // },
    //初始化
    async searchInfo(limit, offest) {
      this.tableData = [];
      let isChoiceness = null; //精选
      let isRecommend = null; //推荐
      //精选
      if (this.value1 == 0) {
        isChoiceness = null;
      }
      if (this.value1 == 1) {
        isChoiceness = 1; //是
      }
      if (this.value1 == 2) {
        isChoiceness = 0; //否
      }
      //推荐
      if (this.value2 == 0) {
        isRecommend = null;
      }
      if (this.value2 == 1) {
        isRecommend = 1; //是
      }
      if (this.value2 == 2) {
        isRecommend = 0; //否
      }
      let data = await QUERYED(
        "post",
        "",
        "  TtArticle(limit: " +
          limit +
          ", offset: " +
          offest +
          ", where: {isChoiceness: {_eq: " +
          isChoiceness +
          "},isRecommend: {_eq: " +
          isRecommend +
          '},title : {_like: "%' +
          this.input +
          '%"}}) {  id img title   author isChoiceness isRecommend}   TtArticle_aggregate(where: {isChoiceness: {_eq: ' +
          isChoiceness +
          "},isRecommend: {_eq: " +
          isRecommend +
          '},title : {_like: "%' +
          this.input +
          '%"}})  { aggregate {  count   }  }'
      );
      this.tableData = data.data.TtArticle;
      this.total = data.data.TtArticle_aggregate.aggregate.count;
    },
    // 删除
    async Delete(row) {
      let data = await DELETE(
        "post",
        "",
        "delete_TtArticle(where: {id: {_eq: " +
          row.id +
          "}}) {  affected_rows }"
      );
      if (data.data.delete_TtArticle.affected_rows > 0) {
        if (this.message("删除", row.title, row.authorid, 0, row.id)) {
          // this.message("删除", row.title, row.authorid, 0, row.id); //发消息通知
          this.$message({
            message: "删除成功",
            type: "success"
          });
          this.searchInfo(this.pageSize, this.pageSize * this.pageNum); //刷新
        }
      }
    },
    //发消息通知
    async message(state, title, authorid, type, articleid) {
      let XT = await INSERT(
        "post",
        "",
        ' insert_XTMessage(objects: {state :"' +
          state +
          '",title :"' +
          title +
          '",userid  :' +
          authorid +
          " ,type :" +
          type +
          " ,articleid :" +
          articleid +
          ',creatime :"' +
          new Date() +
          '"}) { affected_rows  }'
      );
      if (XT.data.insert_XTMessage.affected_rows == 1) {
        return true;
      } else {
        return false;
      }
    },
    detail(id) {
      this.$router.push({
        path: "/articleDetail",
        query: {
          id: id
        }
      });
    },
    // 编辑
    async modify(row) {
      this.$router.push({
        path: "/modify",
        query: {
          id: row.id
        }
      });
    },
    /////查看详情
    async see(row) {
      this.$router.push({
        path: "/seearticle",
        query: {
          id: row.id
        }
      });
    },

    //切换页
    currentPage(val) {
      this.pageNum = val-1 ;
      this.searchInfo(this.pageSize, this.pageSize * this.pageNum);
    }
  }
};
</script>

<style>
.carousel-table {
  margin-top: 20px;
  text-align: center;
  width: 100%;
}
.button-icon {
  margin-bottom: 5px;
  display: inline-block;
  /* float: left; */
  /* width: 240px;
  height: 30px; */
  text-align: center;
  /* line-height: 30px; */
}
.el-dropdown {
  vertical-align: top;
}
.el-dropdown + .el-dropdown {
  margin-left: 15px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>